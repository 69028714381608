<template>
  <div id="linear" style="height: 280px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'assetLinear',

  data() {
    return {
      sortData: [2.8, 2.8, 2.8, 6.2, 8], // 分类统计-数量
      sortMoneyData: [10, 9, 8, 7, 6], // 分类统计-金额
      positionData: [1, 1, 5, 8, 9], // 位置统计-数量
      positionMoneyData: [3, 2, 10, 10, 9, 8], // 位置统计-金额
      useData: [2, 4, 6, 8, 9], // 使用部门统计-数量
      useMoneyData: [1, 1, 10, 10, 1] // 使用部门统计-数金额
    }
  },

  mounted() {
    this.init(1, 'number')
  },

  methods: {
    init(option, val) {
      console.log('option', option)
      console.log('val', val)
      let data = []
      if (option === 1 && val === 'number') {
        data = this.sortData
      } else if (option === 1 && val === 'money') {
        data = this.sortMoneyData
      } else if (option === 2 && val === 'number') {
        data = this.positionData
      } else if (option === 2 && val === 'money') {
        data = this.positionMoneyData
      } else if (option === 3 && val === 'number') {
        data = this.useData
      } else if (option === 3 && val === 'money') {
        data = this.useMoneyData
      }
      // var linearChart
      // if (linearChart != null && linearChart != '' && linearChart != undefined) {
      //   linearChart.dispose()
      // }
      // 基于准备好的dom，初始化echarts电压实例
      var linearChart = echarts.init(document.getElementById('linear'), 'light')
      // 绘制图表
      linearChart.setOption({
        // legend: {},
        // 提示框组件
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: ['办公电器', '办公家具', '办公设备', '车辆', '计算机和网络设备']
        },
        color: ['#6395f9'],
        barWidth: '40%',
        series: [
          {
            name: '分类统计',
            type: 'bar',
            min: 0,
            max: 10,
            interval: 2.5,
            data: data
          }
        ]
      })
      // 监听window窗口大小变化的事件
      window.onresize = function () {
        // 调用echarts实例对象的resize方法
        linearChart.resize()
      }
    },
    handleChange(option, tip) {
      this.init(option, tip)
      console.log('子linearhandleChange', option)
    },
    tipsChange(val, tip) {
      this.init(val, tip)
      console.log('子页面数量和金额-Val', val)
      console.log('子页面数量和金额-tip', tip)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
