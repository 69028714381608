<template>
  <div id="increment" style="width: 100%; height: 310px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'assetIncrement',

  data() {
    return {
      barData1: [2, 4, 7, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
      lineData1: [
        22.0, 22.2, 33.3, 44.5, 65.3, 106.2, 207.3, 238.4, 239.0, 160.5, 126.2
      ],
      barData2: [
        10, 14, 17, 23.2, 35.6, 46.7, 135.6, 162.2, 332.6, 230.0, 633.4, 30.3
      ],
      lineData2: [
        221.0, 22.2, 33.3, 44.5, 65.3, 106.2, 207.3, 338.4, 239.0, 260.5, 26.2
      ]
    }
  },

  mounted() {
    this.init(1)
  },

  methods: {
    init(val) {
      let barData = []
      let linData = []
      if (val === 1) {
        barData = this.barData1
        linData = this.lineData1
      } else if (val === 2) {
        barData = this.barData2
        linData = this.lineData2
      }
      // 基于准备好的dom，初始化echarts电压实例
      var incrementChart = echarts.init(
        document.getElementById('increment'),
        'light'
      )
      // 绘制图表
      incrementChart.setOption({
        // 提示框组件
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          data: ['资产新增数量', '资产新增金额'],
          top: '5%',
          left: 'left'
        },
        xAxis: [
          {
            type: 'category',
            // boundaryGap: false, // 坐标轴两边是否留白
            data: [
              '01月',
              '02月',
              '03月',
              '04月',
              '05月',
              '06月',
              '07月',
              '08月',
              '09月',
              '10月',
              '11月',
              '12月'
            ],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            name: '资产新增金额',
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: '{value} 条'
            }
          },
          {
            show: false,
            type: 'value',
            name: '资产新增数量',
            min: 0,
            max: 500,
            interval: 50,
            axisLabel: {
              formatter: '{value} ¥'
            }
          }
        ],
        series: [
          {
            name: '资产新增数量',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              }
            },
            data: barData
          },
          {
            name: '资产新增金额',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              }
            },
            data: linData
          }
        ]
      })
      // 监听window窗口大小变化的事件
      window.onresize = function () {
        // 调用echarts实例对象的resize方法
        incrementChart.resize()
      }
    },
    incrementChange(val) {
      this.init(val)
      console.log('increment', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
