<template>
  <div id="overview" style="width: 100%; height: 262px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'assetOverview',
  props: ['resize'],
  data() {
    return {
      data1: [
        { value: 2048, name: '空闲 17 (71%)', percent: '71%' },
        { value: 735, name: '在用 4 (17%)', percent: '17%' },
        { value: 580, name: '待审批 2 (8%)', percent: '8%' },
        { value: 484, name: '借用 1 (4%)', percent: '4%' }
      ],
      data2: [
        { value: 735, name: '已损失', percent: '71%' },
        { value: 484, name: '待处置', percent: '17%' },
        { value: 100, name: '故障', percent: '8%' }
      ]
    }
  },

  mounted() {
    this.init(1)
  },
  watch: {
    resize() {
      console.log(this.resize)
      window.onresize = function () {
        // 调用echarts实例对象的resize方法
        this.myChart.resize()
      }
    }
  },
  methods: {
    init(val) {
      let data = []
      if (val === 1) {
        data = this.data1
      } else {
        data = this.data2
      }
      // 基于准备好的dom，初始化echarts电压实例
      var myChart = echarts.init(document.getElementById('overview'), 'light')
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical', // 布局方式，默认为水平布局，可选为：'horizontal' ¦ 'vertical'
          top: '5%',
          right: 'right'
        },
        series: [
          {
            name: '资产概况',
            type: 'pie',
            radius: ['50%', '70%'], // 设置圆饼半径0内圆 1外圆
            avoidLabelOverlap: false, // 防止标签间的重叠
            // 饼图文字显示
            label: {
              // position: 'outer',
              fontSize: 14,
              show: true,
              // position: 'center'
              normal: {
                show: true,
                position: 'inner',
                formatter: function (params) {
                  return params.data.percent
                  // return '总计\r\n24' // 输入回车键会自动变成\r\n
                },
                textStyle: {
                  fontSize: 16,
                  color: '#FFFFFF'
                }
              },
              emphasis: {
                // 圆饼中心文字
                show: true,
                textStyle: {
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              }
            },
            itemStyle: {
              borderWidth: 1,
              borderColor: '#FFFFFF'
            },
            // emphasis: {
            //   // 圆饼中心文字
            //   label: {
            //     show: true,
            //     fontSize: '20',
            //     fontWeight: 'bold'
            //   }
            // },
            // 视觉引导线，对outside有用
            labelLine: {
              normal: {
                legend: 5
              }
            },
            // 选中的效果，能将选择的区域偏离原点一小段距离
            selectedMode: 'single',
            // 饼图需要value和name属性
            data: data
          }
        ]
      })
      // 监听window窗口大小变化的事件
      window.onresize = function () {
        // 调用echarts实例对象的resize方法
        this.myChart.resize()
      }
    },
    change(val) {
      this.init(val)
      console.log('val', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
