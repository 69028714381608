<template>
  <div class="ud-body ud-body-card">
    <a-row :gutter="16">
      <a-col :lg="12" :md="8" :sm="24" :xs="24">
        <a-card :bordered="false">
          <div class="header">
            <div class="header-title">资产增量统计</div>
            <div>
              <a-select
                v-model:value="year"
                :options="selectOption"
                @change="selectChange"
              >
              </a-select>
            </div>
          </div>
          <div class="ud-text-center">
            <Increment ref="incrmentRef"></Increment>
          </div>
        </a-card>
      </a-col>
      <a-col :lg="12" :md="8" :sm="24" :xs="24">
        <a-card :bordered="false">
          <div class="header">
            <div class="header-title">资产概况</div>
            <div class="header-total">
              <div class="total">总数量：24</div>
              <a-radio-group
                v-model:value="radioValue"
                button-style="solid"
                @change="radioChange"
              >
                <a-radio-button value="number">数量</a-radio-button>
                <a-radio-button value="money">金额</a-radio-button>
              </a-radio-group>
            </div>
          </div>
          <div class="ud-text-center">
            <OverView ref="home" v-model:resize="windowResize"></OverView>
          </div>
        </a-card>
        <a-card :bordered="false">
          <div>
            <Examine></Examine>
          </div>
        </a-card>
        <a-card :bordered="false">
          <div>
            <Remind></Remind>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :lg="12" :md="8" :sm="24" :xs="24">
        <a-card :bordered="false">
          <div class="header">
            <div>
              <a-tabs v-model:activeKey="activeKey" @change="tabsChange">
                <a-tab-pane key="1" tab="资产分类统计"></a-tab-pane>
                <a-tab-pane key="2" tab="资产位置统计"></a-tab-pane>
                <a-tab-pane key="3" tab="使用部门统计"></a-tab-pane>
              </a-tabs>
            </div>
            <div>
              <a-radio-group
                v-model:value="sortRadioValue"
                button-style="solid"
                @change="sortRadioChange"
              >
                <a-radio-button value="sortNumber">数量</a-radio-button>
                <a-radio-button value="sortMoney">金额</a-radio-button>
              </a-radio-group>
            </div>
          </div>
          <div class="ud-text-center">
            <Linear ref="lineData"></Linear>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false"> 新手引导 </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import OverView from '@/components/echarts/overview'
import Examine from '@/components/echarts/examine'
import Remind from '@/components/echarts/remind'
import Increment from '@/components/echarts/increment'
import Linear from '@/components/echarts/linear'
export default {
  components: {
    OverView,
    Examine,
    Increment,
    Linear,
    Remind
  },
  data() {
    return {
      windowResize: 0,
      activeIndex: 0,
      // 资产概况的数量（左上）
      radioValue: 'number',
      // 资产分类统计（右下）
      sortRadioValue: 'sortNumber',
      year: '2022',
      // 资产增量统计的选择值（左下）
      selectOption: [
        {
          value: 2022,
          label: '2022'
        },
        {
          value: 2021,
          label: '2021'
        }
      ],
      // 资产分类统计（右下）
      activeKey: '1'
    }
  },
  mounted() {
    this.init()
  },
  created() {},
  methods: {
    init() {
      window.onresize = function () {
        // 调用echarts实例对象的resize方法
        this.windowResize = new Date().getTime()
      }
    },
    btnClick(index) {
      this.activeIndex = index
    },
    // 资产概况的数量金额选择（左上）
    radioChange(e) {
      console.log(e.target.value)
      if (e.target.value === 'number') {
        this.$refs.home.change(1)
      } else {
        this.$refs.home.change(2)
      }
    },
    // 资产增量统计的选择器（左下）
    selectChange(value) {
      // console.log('year', typeof value)
      this.year = value
      if (this.year === 2022) {
        this.$refs.incrmentRef.incrementChange(1)
      } else if (this.year === 2021) {
        this.$refs.incrmentRef.incrementChange(2)
      }
    },
    // 资产分类统计（右下）
    tabsChange(e) {
      console.log('父tabsChange', e)
      if (e === '1' && this.sortRadioValue === 'sortNumber') {
        this.$refs.lineData.handleChange(1, 'number')
      } else if (e === '1' && this.sortRadioValue === 'sortMoney') {
        this.$refs.lineData.handleChange(1, 'money')
      } else if (e === '2' && this.sortRadioValue === 'sortNumber') {
        this.$refs.lineData.handleChange(2, 'number')
      } else if (e === '2' && this.sortRadioValue === 'sortMoney') {
        this.$refs.lineData.handleChange(2, 'money')
      } else if (e === '3' && this.sortRadioValue === 'sortNumber') {
        this.$refs.lineData.handleChange(3, 'number')
      } else if (e === '3' && this.sortRadioValue === 'sortMoney') {
        this.$refs.lineData.handleChange(3, 'money')
      }
    },
    // 资产分类统计（右下）
    sortRadioChange(e) {
      if (this.activeKey === '1' && e.target.value === 'sortNumber') {
        this.$refs.lineData.tipsChange(1, 'number')
      } else if (this.activeKey === '1' && e.target.value === 'sortMoney') {
        this.$refs.lineData.tipsChange(1, 'money')
      } else if (this.activeKey === '2' && e.target.value === 'sortNumber') {
        this.$refs.lineData.tipsChange(2, 'number')
      } else if (this.activeKey === '2' && e.target.value === 'sortMoney') {
        this.$refs.lineData.tipsChange(2, 'money')
      } else if (this.activeKey === '3' && e.target.value === 'sortNumber') {
        this.$refs.lineData.tipsChange(3, 'number')
      } else if (this.activeKey === '3' && e.target.value === 'sortMoney') {
        this.$refs.lineData.tipsChange(3, 'money')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  .header-title {
    font-weight: bold;
    font-size: 20px;
  }
  .header-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .total {
      margin-right: 10px;
    }
  }
}
</style>
