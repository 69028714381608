<template>
  <div>
    <a-row :gutter="16">
      <a-col :lg="24" :md="24" :sm="24" :xs="24">
        <a-card class="remind" :bordered="false">
          <div class="" style="font-weight: bold; font-size: 20px">
            预警与提醒<span style="margin-left:5px">{{ total }}</span>
          </div>
          <div class="content">
            <a-tabs
              v-model:activeKey="activeKey"
              :style="{ width: '100%' }"
              :tabBarStyle="{}"
              @prevClick="prevClick"
              @nextClick="nextClick"
              @change="tabsChange"
            >
              <a-tab-pane
                :key="index"
                v-for="(item, index) in list"
              >
                <template #tab>
                  <div class="tabBox">
                    <div>
                      <img
                        :src="item.img"
                        alt=""
                        style="width: 50px; height: 50px; margin-right: 5px"
                      />
                    </div>
                    <div class="tabBox-title">
                      <div style="font-size: 18px; font-weight: bold">
                        {{ item.num }}
                      </div>
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'assetExamine',

  data() {
    return {
      activeKey: '',
      list: [
        {
          img: '/static/icon/jieyong.png',
          num: 1,
          title: '借用到期资产'
        }
      ]
    }
  },

  mounted() {},
  computed: {
    total() {
      let total = 0
      this.list.map((item) => {
        total += item.num
      })
      return total
    }
  },
  methods: {
    prevClick(e) {
      console.log('左边', e)
    },
    nextClick(e) {
      console.log('右边', e)
    },
    tabsChange(e) {
      console.log('选择了', e)
      if (e === 1) {
      }
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-card-body) {
  padding: 0px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .tabBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tabBox-title {
      display: flex;
      flex-direction: column;
    }
  }
  :deep(.ant-card-body){
      padding: 20px;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab-active) {
    // color: rgba(0, 0, 0, 0.85);
    color: #666;
    background: #fff;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab:hover) {
    // color: rgba(0, 0, 0, 0.85);
    color: #666;
    background: #fff;
  }
  :deep(.ant-tabs-bar) {
    border-bottom: 0px;
    margin: 0px
  }

  :deep(.ant-tabs-ink-bar) {
    background-color: #fff;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab){
      margin: 0px 5px 0 0;
      padding: 5px 10px;
  }
}
:deep(div.remind) {
  margin-bottom: 0px !important;
}
</style>
